<template>
  <div>
    <el-dialog :visible.sync="isdailog" width="880px" center class="diags" @open="openwangji" @close="closedailog">
      <div class="dialog-loginbox">
        <div class="letimg">
          <img :src="$store.state.weblogo" />
        </div>

        <div class="wangrightfrom">
          <h1>忘记密码</h1>
          <el-form class="wangfrom" v-if="wangformxia">
            <el-form-item>
              <el-input placeholder="请输入手机号码" class="input-witselect" v-model="wangForm.phone">
                <el-select v-model="begin" slot="prepend" placeholder="+852">
                  <el-option label="+852" value="+852"></el-option>
                </el-select>
                <el-button slot="append" @click="getSends">{{ text }}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="请输入短信验证码" v-model="wangForm.sms_code">
                <template slot="prepend">
                  <span>验证码</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="rlbtn">
              <el-button type="primary" @click="Xiayibu">下一步</el-button>
            </el-form-item>
          </el-form>
          <el-form class="wangfrom" v-else>
            <el-form-item>
              <el-input placeholder="请输入新密码" v-model="wangForm.password" maxlength="6" show-password>
                <template slot="prepend">新登录密码</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="重复新证码" v-model="wangForm.password_confirmation" maxlength="6" show-password>
                <template slot="prepend">确认密码</template>
              </el-input>
            </el-form-item>
            <el-form-item class="rlbtn">
              <el-button type="primary" @click="sumoitWang">完成</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPassword, getchecksms } from '@/http/api.js'
export default {
  props: {
    showwang: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      wangformxia: true, // 忘记密码显示与隐藏
      wangForm: {
        password: '',
        password_confirmation: '',
        phone: '',
        sms_code: '',
      },

      text: '发送验证码',
      begin: '+86',
      timenull: null,
      isdailog: this.showwang
    }
  },
  methods: {

    // 打开忘记密码 -> 弹窗
    openwangji() {
      this.wangForm.phone = ''
      this.wangForm.sms_code = ''
      this.wangformxia = true
    },



    // 忘记密码请求
    sumoitWang() {
      getPassword(this.wangForm)
        .then(res => {
          this.$message.success(res.message)
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },


    Xiayibu() {
      this.wangForm.sms_code == '' ? this.$message.error('请先获取验证码！') : this.wangformxia = false
    },


    // 发送验证码
    getSends() {
      if (!this.timenull) {
        if (this.wangForm.phone !== '') {
          let phone_number = this.begin + this.wangForm.phone
          getchecksms(phone_number)
            .then(() => {
              this.$message.success('发送验证码成功，请注意查收！')
            })
            .catch(err => {
              console.log(err.message)
              this.$message.warning(err.message)
            })
          let time = 60
          this.timenull = setInterval(() => {
            if (time > 0 && time <= 60) {
              time--
              this.text = time + 's 后重新发送'
            } else {
              clearInterval(this.timenull)
              this.timenull = null
              this.text = '重新发送'
            }
          }, 1000)
        } else {
          this.$message.error('请输入手机号码')
        }
      }
    },

    closedailog() {
      this.$emit('showWangji', false)
    }
  },
  watch: {
    showwang(val) {
      this.isdailog = val
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-loginbox {
  width: 100%;
  height: 320px;
  display: flex;

  .letimg {
    width: 440px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 380px;
      height: 173px;
    }
  }
}



.wangrightfrom {
  width: 440px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .wangfrom {
    width: 380px;
    height: 100%;

    .input-witselect ::v-deep .el-input-group__prepend {
      width: 97px;
    }

    .rlbtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button {
        width: 300px;
      }
    }

    ::v-deep .el-form-item:first-child {
      margin-top: 22px;
    }

    ::v-deep .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}


::v-deep .diags .el-dialog {
  background-color: #29283e;
}
</style>
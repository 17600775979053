<template>
  <div>
    <el-dialog :visible.sync="showForm" width="880px" center @close="resetForm" :destroy-on-close="true"
      :close-on-click-modal="false">
      <div class="lettop">
        <span>已有账号？</span>
        <span @click="SetLoginpopup">立即登录</span>
      </div>
      <div class="dialog-content">
        <div class="setops">
          <el-steps :active="stepsIndex" align-center>
            <el-step title="账户资讯"></el-step>
            <el-step title="完善个人资讯"></el-step>
            <el-step title="注册成功"></el-step>
          </el-steps>
        </div>

        <div class="formbox" v-if="stepsIndex === 1">
          <el-form ref="requestForm" :rules="rulestwo" :model="requestForm">
            <el-form-item prop="name">
              <el-input placeholder="请输入6-12位数字与字母组合" v-model="requestForm.name">
                <template slot="prepend">账号</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入6-12位数字与字母组合" show-password v-model="requestForm.password">
                <template slot="prepend">密码</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password_confirmation">
              <el-input placeholder="请输入6-12位数字与字母组合" show-password v-model="requestForm.password_confirmation">
                <template slot="prepend">确认密码</template>
              </el-input>
            </el-form-item>

            <el-form-item prop="lang">
              <div style="display: flex">
                <div class="inputprepend">货币种类</div>
                <div class="elsect">
                  <el-select placeholder="请选择货币种类" v-model="requestForm.lang">
                    <el-option label="￥人民币" value="zh_cn"></el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>

            <el-form-item class="el-item-bottombox">
              <el-button type="primary" @click="submitForm" class="xia-btn">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="formbox" v-if="stepsIndex === 2">
          <el-form :model="requestForm" :rules="rulestwo" ref="requestForm">
            <el-form-item prop="realname" v-if="Config.isRealNameRequred == '1'">
              <el-input placeholder="必须与您存提款的银行帐户名字相同" v-model="requestForm.realname">
                <template slot="prepend">真实姓名</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="qk_pwd">
              <el-input placeholder="请输入6位取款密码" maxlength="6" show-password v-model="requestForm.qk_pwd">
                <template slot="prepend">取款密码</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="phone" v-if="Config.isPhoneRequired == '1'">
              <el-input placeholder="建议使用常用手机号码" maxlength="11" minlength="8" class="with-select" v-model="requestForm.phone">
                <el-select slot="prepend" placeholder="+86" style="width: 97px" filterable v-model="benumber">
                  <el-option label="+86" value="+86"></el-option>
                </el-select>

                <el-button slot="append" @click="Sendsms">{{ smstext }}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="sms_code" v-if="is_sms == '1'">
              <el-input placeholder="请输入短信验证码" maxlength="6" v-model="requestForm.sms_code">
                <template slot="prepend">
                  <span>验证码</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="el-item-bottombox">
              <el-button type="primary" @click="submitRequest('requestForm')" class="xia-btn">注册</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="formbox" v-if="stepsIndex === 3">
          <el-result icon="success" title="注册成功" subTitle="请点击左上角跳转到登录页面"></el-result>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getchecksms, getRegister, getConfig } from '@/http/api'
export default {
  props: {
    showRegister: {
      type: Boolean,
      default: false
    },
    openDrawing: {
      type: Function,
      default: null
    },
 
  },
  data() {
    let password = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.requestForm.password !== '') {
          this.$refs.requestForm.validateField('password_confirmation')
        }
        callback()
      }
    }
    let password_confirmation = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.requestForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      requestForm: {
        name: '',
        password: '',
        password_confirmation: '',
        qk_pwd: '',
        phone: '',
        sms_code: '',
        realname: '',
        register_site: '',
        key: '',
        captcha: '',
        invite_code: '',
        lang: '',
      },

      stepsIndex: 1, // 注册 索引
      rulestwo: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { minlength: 6, maxlength: 12, message: '用户名长度在 6 到 12 个字符', trigger: 'blur' },
        ],
        password: [{ validator: password, trigger: 'blur' }],
        password_confirmation: [{ validator: password_confirmation, trigger: 'blur' }],
        realname: [{ required: true, message: '个人姓名不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          { minlength: 8, message: '手机号码最少为8位', trigger: 'blur' },
          { maxlength: 11, message: '手机号码最多为11位', trigger: 'blur' },
        ],
        qk_pwd: [
          { required: true, message: '取款密码不能为空', trigger: 'blur' },
          { maxlength: 6, message: '取款密码为6位', trigger: 'blur' },
        ],
        sms_code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
          { minlength: 6, message: '验证码最少为6位', trigger: 'blur' },
          { maxlength: 6, message: '验证码最多为6位', trigger: 'blur' },
        ],
        lang: [{ required: true, message: '请选择货币种类', trigger: 'blur' }],
      },
      benumber: '+86', // 默认手机前缀
      smstext: '发送验证码',
      timer: null, // 短信验证码定时器
      showForm: this.showRegister ,// 注册弹窗
      Config:null,
      is_sms: null
    }
  },
  methods: {
    //注册弹窗关闭后 -> 表单重置
    resetForm() {
      this.stepsIndex = 1
      this.requestForm.name = ''
      this.requestForm.password = ''
      this.requestForm.password_confirmation = ''
      this.requestForm.lang = ''
      this.requestForm.qk_pwd = ''
      this.requestForm.realname = ''
      this.requestForm.phone = ''
      this.requestForm.sms_code = ''

      this.$emit('closeRegiter', false)
    },

    // 注册第一页验证通过
    submitForm() {
      this.$refs.requestForm.validate(val => {
        if (val) {
          this.stepsIndex = 2
        } else {
          return false
        }
      })
    },

    // 提交注册表单
    submitRequest(fname) {
      this.$refs[fname].validate(val => {
        if (val) {
           this.Config.isCaptchaRequired == '1' ? this.$store.dispatch('ChangeCaptcha',true) : this.SetReact()  // 是否要打开验证码 
        } else {
          return false
        }
      })
    },

    // 注册请求
    SetReact(){
          this.requestForm.phone = this.benumber + this.requestForm.phone
          getRegister(this.requestForm)
            .then(() => {
              this.$message.success({
                message: '恭喜您，注册成功！',
              })
              this.stepsIndex = 3
            })
            .catch(err => {
              this.requestForm.phone = ''
              this.$message.error(err.message)
      })
    },


    // 发送验证码
    Sendsms() {
      if (!this.timer) {
        let phone_number = this.benumber + this.requestForm.phone
        getchecksms(phone_number)
          .then(() => {
            this.$message.success({
              message: '发送验证码成功，请注意查收！',
            })
          })
          .catch(err => {
            this.$message.warning(err.message)
          })
        let time = 60
        this.timer = setInterval(() => {
          if (time > 0 && time <= 60) {
            time--
            this.smstext = time + 's 后重新发送'
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.smstext = '重新发送'
          }
        }, 1000)
      }
    },

    // 打开登录弹窗
    SetLoginpopup() {
      this.$emit('closeRegiter', false)
      setTimeout(() => {
        this.$emit('upLogin', true)
      }, 300)
    },

    // 获取 当前url 的参数
    Paramsjson(url) {
      let urlStr = url.split('?')[1]
      let paramsArr = urlStr.split('&')
      let obj = {}
      for (let i = 0; i < paramsArr.length; i++) {
        obj[paramsArr[i].split('=')[0]] = paramsArr[i].split('=')[1]
      }
      if (obj.i != 'undefined') {
        this.requestForm.invite_code = obj.i
        this.$emit('closeRegiter', true)
      }
    },



   async RegisterConfig(){
      try{
        let url = window.location.host
        let res = await getConfig(url,'register')
        this.is_sms = res.data.vip1_is_register_sms_open
        let bjarr = {openlogin:res.data.vip1_is_login_captcha_open,smsopen:res.data.vip1_is_register_sms_open}
        this.$emit('isloginChptcha',bjarr)
        this.Config = JSON.parse(res.data.register_setting_json)
      }catch(err){
        console.log(err.message);
      }
    },

   async systemConfig() {
      try {
        let width = window.document.body.clientWidth
        let url = window.location.host
        let { data: res } = await getConfig(url, 'system')
        this.$store.dispatch('handLogo',res.site_logo)
        if(width < 960) {
          window.location.replace(res.site_mobile)
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  },

  watch: {
    showRegister(nval) {
      this.showForm = nval
    },
    '$store.state.key'(e){
      this.requestForm.key = e
      e  && this.requestForm.password !== '' ? this.SetReact() : ''
    }
  },

  mounted() {
    let host = window.location.host
    let url = window.location.href
    url.indexOf('?') > -1 ? this.Paramsjson(url) : ''
    this.requestForm.register_site = host  
    this.RegisterConfig()
    this.systemConfig()
  }


}
</script>

<style lang="less" scoped>
/* 弹窗样式 */
.lettop {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 8px;
  top: 8px;
  line-height: 40px;
  text-align: center;
  color: #818891;
  background-color: #dae2eb33;

  span:last-child {
    color: #4e9cf1;
    text-decoration: underline;
    cursor: pointer;
  }
}

.dialog-content {
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  padding: 30px 20px;

  .setops {
    width: 664px;
    height: 50px;
    margin: 0 auto;
  }

  .formbox {
    width: 470px;
    height: 400px;
    margin: 50px auto 0;

    ::v-deep .el-form-item__error {
      left: unset;
      right: 0;
    }

    .el-item-bottombox {
      display: flex;
      justify-content: center;
      align-items: center;

      .xia-btn {
        width: 470px;
        height: 50px;
        font-size: 20px;
        margin: 0 auto;
      }
    }

    .inputprepend {
      background-color: #f5f7fa;
      color: #909399;
      display: inline-block;
      position: relative;
      border: 1px solid #dcdfe6;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0 20px;
      width: 97px;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      height: 40px;
    }

    .elsect {
      width: 373px;
      height: 40px;
      display: inline-block;

      .el-select {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}
</style>
<template>
  <div id="app">
    <Header v-if="$route.meta.showHread"></Header>
    <router-view></router-view>
    <Footer v-if="$route.meta.showHread"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Headers/Headerpage.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer,
    Header,
  },
}
</script>

<style>
*,
body,
html {
  margin: 0;
  padding: 0;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}



a {
  text-decoration: none;
  color: #ffffff;
}

.router-link-active {
  text-decoration: none;
}

body::-webkit-scrollbar {
  display: none;
}

img {
  width: 100%;
  height: 100%;
}

body,html .el-popup-parent--hidden {
  padding-right: 0 !important;
}


::v-deep .el-dialog__wrapper{
  transition: all .3s ease;
}

::v-deep .el-loading-mask{
  transition: all .3s ;
  z-index: 99999999999999999999;
}

</style>

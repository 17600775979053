import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    islogin: window.localStorage.getItem('islogin') || 0,
    token: JSON.parse(window.localStorage.getItem('token')) || {},
    userinfo: JSON.parse(window.localStorage.getItem('userinfo')) || {},
    isFanshui: false,  
    chackCaptcha: false,
    key: null,
    weblogo: window.localStorage.getItem('logo') || null
  },
  getters: {
  },
  mutations: {

    // 登录状态
    SET_ISLOGIN(state,data){
      state.islogin = data
    },

    // token
    SET_TOKEN(state,data){
      state.token = data
    },

    // 用户信息
    SET_INFO(state,data){
      state.userinfo = data
    },

      // 反水
    SET_ISFANGSHUI(state,data){
      state.isFanshui = data
    },

    // 验证码打开与关闭
    SET_ISCAPTCHA(state,data){
      state.chackCaptcha = data
    },

    // 存入key 值
    SET_KEY(state,data){
      state.key = data
    },

    SET_LOGO(state,data){
      state.weblogo = data
    }

  },
  actions: {

    CunisLogin({commit}, data){
      window.localStorage.setItem('islogin',data)
      commit('SET_ISLOGIN',data)
    },

    CunToken({commit}, data){
      window.localStorage.setItem('token',JSON.stringify(data))
      commit('SET_TOKEN',data)
    },


    CunInfo({commit},data){
      window.localStorage.setItem('userinfo',JSON.stringify(data))
      commit('SET_INFO',data)
    },

    ChangeCaptcha({commit},data){
      commit('SET_ISCAPTCHA',data)
    },

    handLogo({commit},data){
      window.localStorage.setItem('logo',data)
      commit('SET_LOGO',data)
    }

  },
})

<template>
  <div>
    <el-dialog :visible.sync="showDailog" width="880px" center class="diags" @opened="openwangji"  @close="closedailog">
      <div class="dialog-loginbox">  
        <div class="letimg">
          <img :src="$store.state.weblogo" />
        </div>

        <div class="wangrightfrom">


          <el-form class="wangfrom" :model="FormData" :rules="rules" ref="ruleForm" :show-message="true">

            <el-form-item prop="phone">
              <el-input placeholder="请输入手机号码"  size="large" class="input-witselect" v-model="FormData.phone" maxlength="11">
                <el-select v-model="begin" slot="prepend" placeholder="+852">
                  <el-option label="+86" value="+86"></el-option>
                </el-select>
                <el-button slot="append" @click="getSends">{{ codetext }}</el-button>
              </el-input>
            </el-form-item>

            <el-form-item prop="sms_code">
              <el-input placeholder="请输入短信验证码"  size="large" v-model="FormData.sms_code" maxlength="6">
                <template slot="prepend">
                  <span>验证码</span>
                </template>
              </el-input>
            </el-form-item> 

            <el-form-item class="rlbtn">
              <el-button type="primary"  @click="submitForm('ruleForm')">立即登录 / 注册</el-button>
            </el-form-item>

          </el-form>


        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getchecksms,getkuaiLogin, getme } from '@/http/api.js'
export default {
  props: {
    showLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      FormData: {
        phone: '',
        sms_code: '',
        lang:'zh_cn',
        invite_code:'',
        register_site: window.location.host
      },

      codetext: '发送验证码',
      begin: '+86',
      timenull: null,
      showDailog: this.showLogin,

      rules:{
        phone:[
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 8, message: '手机号码长度在 11 个字符', trigger: 'blur' }
        ],
        sms_code:[{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  methods: {

    // 打开弹窗
    openwangji() {
      this.FormData.phone = ''
      this.FormData.sms_code = ''
      this.$refs.ruleForm.clearValidate()
    },

    // 关闭弹窗
     closedailog() {
      this.$emit('closeDaiLogin', false)
    },


    // 获取 当前url 的参数
    Paramsjson() {
      let url = window.location.href
      if(url.indexOf('?') > -1){
        let urlStr = url.split('?')[1]
        let paramsArr = urlStr.split('&')
        let obj = {}
        for (let i = 0; i < paramsArr.length; i++) {
          obj[paramsArr[i].split('=')[0]] = paramsArr[i].split('=')[1]
        }
        if (obj.i != 'undefined') {
          this.FormData.invite_code = obj.i
        }
      } 
    },
 


    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
         this.Paramsjson()
         this.setkuLogin()
        } 
      });
    },


    // 登录请求
    setkuLogin(){
      getkuaiLogin(this.FormData).then(res =>{
        this.$store.dispatch('CunisLogin', 1)
        this.$store.dispatch('CunToken', res.data)
        
        this.$message.success('登入成功！')
        getme().then(red=>{
          this.$store.dispatch('CunInfo', red.data)
        })

        this.closedailog()

      }).catch(err =>{
        this.$message.error(err.message)
      })
    },


    // 发送验证码
    getSends() {
      if (!this.timenull) {
        if (this.FormData.phone !== '') {
    
          getchecksms(this.FormData.phone)
            .then(() => {
              this.$message.success('发送验证码成功，请注意查收！')
            })
            .catch(err => {
              this.$message.warning(err.message)
            })
          let time = 60
          this.timenull = setInterval(() => {
            if (time > 0 && time <= 60) {
              time--
              this.codetext = time + 's 后重新发送'
            } else {
              clearInterval(this.timenull)
              this.timenull = null
              this.codetext = '重新发送'
            }
          }, 1000)
        } else {
          this.$message.error('请输入手机号码')
        }
      }


    },

  },
  watch: {
    showLogin(val) {
      this.showDailog = val
    }
  },

}
</script>

<style lang="less" scoped>
.dialog-loginbox {
  width: 100%;
  height: 284px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .letimg {
    width: 440px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 380px;
      height: 173px;
      object-fit: contain;
    }
  }
}



.wangrightfrom {
  width: 440px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wangfrom {
    width: 390px;
    height: 100%;

    .input-witselect ::v-deep .el-input-group__prepend {
      width: 97px;
    }

    

    .rlbtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button {
        width: 300px;
        height: 40px;
        font-size: 16px;
      }
    }

    ::v-deep .el-form-item:first-child {
      margin-top: 22px;
    }

    ::v-deep .el-form-item:last-child {
      margin-bottom: 0;
    }
    ::v-deep .el-form-item__error{
      left: unset;
      right: 0;
    }
  }
}


::v-deep .diags .el-dialog {
  background-color: #29283e;
}


</style>
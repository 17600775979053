import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/Index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      showHread: true,
    },
    component: Index,
  },
  {
    path: '/Hot',
    name: 'Hot',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Hot.vue'),
  },
  {
    path: '/Promotion',
    name: 'Promotion',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Promotion.vue'),
  },
  {
    path: '/Esports',
    name: 'Esports',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Esports.vue'),
  },
  {
    path: '/Live',
    name: 'Live',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Live.vue'),
  },
  {
    path: '/Game',
    name: 'Game',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Game.vue'),
  },
  {
    path: '/Sport',
    name: 'Sport',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Sport.vue'),
  },
  {
    path: '/Board',
    name: 'Board',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Board.vue'),
  },
  {
    path: '/Lottery',
    name: 'Lottery',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Lottery.vue'),
  },
  {
    path: '/Fish',
    name: 'Fish',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Fish.vue'),
  },
  {
    path: '/Zhuanpan',
    name: 'Zhuanpan',
    meta: {
      showHread: false,
    },
    component: () => import('../views/Zhuanpan.vue'),
  },
  {
    path: '/activity/:id',
    name: 'activity',
    meta: {
      showHread: false,
    },
    component: () => import('../views/activity.vue'),
  },
  {
    path: '/Yu-EBao',
    name: 'Yu-EBao',
    meta: {
      showHread: false,
    },
    component: () => import('../views/Member/YuE-Bao.vue'),
  },
  {
    path: '/LoginToSupplier',
    name: 'LoginToSupplier',
    meta: {
      showHread: false,
    },
    component: () => import('../views/LoginToSupplier.vue'),
  },
  {
    path: '/Hotgame',
    name: 'Hotgame',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Hotgame.vue'),
  },
  {
    path: '/AboutUs/:id',
    name: 'AboutUs',
    meta: {
      showHread: true,
    },
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/Red',
    name: 'Red',
    meta: {
      showHread: false,
    },
    component: () => import('../views/Red.vue'),
  },
  {
    path: '/Register',
    name: 'Register',
    meta: {
      showHread: false,
    },
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/Member',
    name: 'Member',
    meta: {
      showHread: true,
    },
    component: () => import('../views/Member/Member.vue'),
    children: [
      {
        path: 'Transfer',
        name: 'Transfer',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Transfer.vue'),
      },
      {
        path: 'Transaction',
        name: 'Transaction',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Transaction.vue'),
      },
      {
        path: 'RebateList',
        name: 'RebateList',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/RebateList.vue'),
      },
      {
        path: 'AgentGuide',
        name: 'AgentGuide',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/AgentGuide.vue'),
      },
      {
        path: 'Team',
        name: 'Team',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Team.vue'),
      },
      {
        path: 'Suggestion',
        name: 'Suggestion',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Suggestion.vue'),
      },
      {
        path: 'SiteMail',
        name: 'SiteMail',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/SiteMail.vue'),
      },
      {
        path: 'Collect',
        name: 'Collect',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Collect.vue'),
      },
      {
        path: 'MemberInfo',
        name: 'MemberInfo',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/MemberInfo.vue'),
      },
      {
        path: 'BankCard',
        name: 'BankCard',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/BankCard.vue'),
      },
      {
        path: 'Vip',
        name: 'Vip',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Vip.vue'),
      },
      {
        path: 'Deposit',
        name: 'Deposit',
        meta: {
          showHread: true,
        },
        component: () => import('../views/Member/Deposit.vue'),
      },
      {
        path: 'Withdraw',
        name: 'Withdraw',
        meta: {
          showHread: true, 
        },
        component: () => import('../views/Member/Withdraw.vue'),
      },
      {
        path: 'AgentApply',
        name: 'AgentApply',
        meta: {
          showHread: true, 
        },
        component: () => import('../views/Member/AgentApply.vue'),
      },
      {
        path: 'DailyBonus',
        name: 'DailyBonus',
        meta: {
          showHread: true, 
        },
        component: () => import('../views/Member/DailyBonus.vue'),
      },
      {
        path: 'DailyBonusLog',
        name: 'DailyBonusLog',
        meta: {
          showHread: true, 
        },
        component: () => import('../views/Member/DailyBonusLog.vue'),
      },
    ],
  },
  {
    path: '*',
    name: '404',
    meta: {
      showHread: true,
    },
    component: () => import('../views/404.vue'),
  },

]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from,savedPosition) {
    return { x:0 , y: 0}
  }



})

export default router

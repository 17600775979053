<template>
  <div>
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel trigger="click" height="700px" indicator-position="none">
        <el-carousel-item v-for="(item, indexd) in bannerslist" :key="indexd">
          <img v-lazy="item.url" :key="item.url" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 公告 -->
    <div class="noticebox">
      <div class="notice-content">
        <img src="@/assets/home/notice.png" class="notice-icon" />
        <div class="notice-text">
          <p class="spantext">{{ notionesText }}</p>
        </div>
        <el-button round type="warning" class="noticebtn">更多</el-button>
      </div>
    </div>

    <!-- 公告下三个模块 -->
    <div class="wapbox">
      <div class="rowbox">
        <div class="wap-content">
          <img v-lazy="require('@/assets/home/waplet1.png')"  class="let-icon" />
          <div class="span-text">
            <h4>拜仁慕尼克足球俱乐部</h4>
            <p>亚洲官方赞助商</p>
          </div>
          <img v-lazy="require('@/assets/home/wapicon1.png')" class="rht-icon" />
        </div>
        <div class="wap-content">
          <img v-lazy="require('@/assets/home/waplet2.png')"  class="let-icon" />
          <div class="span-text">
            <h4>阿根廷国家足球队</h4>
            <p>亚洲官方独家咨询合作伙伴</p>
          </div>
          <img v-lazy="require('@/assets/home/wapicon2.png')"  class="rht-icon" />
        </div>
        <div class="wap-content">
          <img v-lazy="require('@/assets/home/waplet3.png')" class="let-icon" />
          <div class="span-text">
            <h4>史蒂文 · 杰拉德</h4>
            <p>亚洲体育形象大使</p>
          </div>
          <img v-lazy="require('@/assets/home/wapicon3.png')" class="rht-icon" />
        </div>
      </div>
    </div>

    <!-- 安全畅快 -->
    <div class="title1-box">
      <div class="title1-contnent">
        <p>安全畅快 实时投注</p>
      </div>
    </div>

    <div class="setion1-box">
      <div class="setion1-content">
        <div class="setion1-leftbox" v-for="(item, ix) in listy" :key="ix" v-show="tetx_active === ix">
          <img v-lazy="item.let_img" />
        </div>
        <div class="setion1-rightbox">
          <div class="right-titlebox">
            <div
              class="tiltletext"
              :class="tetx_active == ins ? 'title-bg_active' : 'title-bg'"
              v-for="(item, ins) in listy"
              :key="ins"
              @click="change(ins)"
            >
              <span>{{ item.title }}</span>
            </div>
          </div>

          <div class="right-bottombox">
            <div class="text-p" v-for="(item, i) in listy" :key="i" v-show="tetx_active === i">
              <h1>{{ item.title }}</h1>
              <p>{{ item.textp }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 热门游戏 -->
    <div class="title2-box">
      <div class="title2-contnent">
        <div class="title2-img"></div>
      </div>
    </div>

    <div class="setion2-box">
      <div class="setion2-box_title">
        <div class="t-title-box">
          <div
            class="t-title"
            v-for="(item, indexss) in titledata"
            :key="indexss"
            :class="titleIndex == indexss ? 'title-bg_active' : 'title-bg'"
            @click="Titlechange(item.ID)"
          >
            <span>{{ item.txt }}</span>
          </div>
        </div>
      </div>

      <div class="setion2-content" v-if="titleIndex == 0">
        <div class="setion2-letbox">
          <img  src="../assets/home/namelet.png" class="top1" />
          <img  src="../assets/home/namelet0.png" class="top2" />
          <p>
            千种游戏,多重累计奖金,坚持创新、打造精品,通过丰富的游戏世界观,精妙的游戏设计,创新的规则玩法,
            为您提供卓越极致的游戏体验。
          </p>
        </div>
        <div class="setion2-conterbox">
          <img  src="@/assets/home/dz_logo.png" class="dzlogo" />
          <img  src="@/assets/home/dz_zs1.png" class="flastimg" />
        </div>
        <div class="setion2-rightbox">
          <img  src="@/assets/home/ty_bg0.png" />

          <div class="more">
            <div class="right-p">
              <h1>更多场馆</h1>
              <p>MORE VENUES</p>
            </div>
            <div class="right-p_two" @click="SetRouter('Game')">
              <h1>进入游戏</h1>
              <p>ENTER GAME</p>
            </div>
          </div>
        </div>
      </div>

      <div class="setion2-content" v-if="titleIndex == 1">
        <div class="setion2-letbox">
          <img  src="@/assets/home/namelet.png" class="top1" />
          <img  src="@/assets/home/zr.png" class="top2" />
          <p>
            最新最炫的真人线上娱乐,经典、咪牌、包桌百家乐随心选择,美女如云多终端相容,
            数百比特受过专业培训的荷官和模拟的赌场环境,更可让玩家尽享乐趣。
          </p>
        </div>
        <div class="setion2-conterbox">
          <img src="@/assets/home/zr_logo.png" class="zr-logo" />
          <img src="@/assets/home/zr_zs1.png" class="zr-icon1" />
          <img src="@/assets/home/zr_zs2.png" class="zr-icon2" />
        </div>
        <div class="setion2-rightbox">
          <img src="@/assets/home/zr_btn.png" />

          <div class="more">
            <div class="right-p">
              <h1>更多场馆</h1>
              <p>MORE VENUES</p>
            </div>
            <div class="right-p_two" @click="SetRouter('Live')">
              <h1>进入游戏</h1>
              <p>ENTER GAME</p>
            </div>
          </div>
        </div>
      </div>

      <div class="setion2-content" v-if="titleIndex == 2">
        <div class="setion2-letbox">
          <img src="@/assets/home/namelet.png" class="top1" />
          <img src="@/assets/home/by.png" class="top2" />
          <p>
            极具多样性玩法的捕鱼类游戏受到不少游戏玩家的喜爱,花样的鱼潮,华丽的鱼阵,丰富的鱼种,
            超高爆率,一炮即中！带给你不一样的捕鱼盛宴,来体验一下捕鱼的乐趣吧！
          </p>
        </div>
        <div class="setion2-conterbox">
          <img src="@/assets/home/by_logo.png" class="by-logo" />
          <img src="@/assets/home/by_zs.png" class="by-icon1" />
          <img src="@/assets/home/by_zs2.png" class="by-icon2" />
        </div>
        <div class="setion2-rightbox">
          <img src="@/assets/home/by_btn.png" />

          <div class="more">
            <div class="right-p">
              <h1>更多场馆</h1>
              <p>MORE VENUES</p>
            </div>
            <div class="right-p_two" @click="SetRouter('Fish')">
              <h1>进入游戏</h1>
              <p>ENTER GAME</p>
            </div>
          </div>
        </div>
      </div>

      <div class="setion2-content" v-if="titleIndex == 3">
        <div class="setion2-letbox">
          <img src="@/assets/home/namelet.png" class="top1" />
          <img src="@/assets/home/cp.png" class="top2" />
          <p>
            业界首创区块链彩票,多地域的官方时时彩、11选5、PK10、PC蛋蛋和官方六合彩,
            足够新颖且极易操作的游戏介面,更是在您游戏的过程中增光添彩。
          </p>
        </div>
        <div class="setion2-conterbox">
          <img src="@/assets/home/cp_logo.png" class="cp_logo" />
          <img src="@/assets/home/cp_zs1.png" class="cp_icon1" />
          <img src="@/assets/home/cp_zs2.png" class="cp_icon2" />
          <img src="@/assets/home/cp_zs3.png" class="cp_icon3" />
          <img src="@/assets/home/cp_zs4.png" class="cp_icon4" />
        </div>
        <div class="setion2-rightbox">
          <!-- <img src="@/assets/ty_btn.png" />

          <div class="more">
            <div class="right-p">
              <h1>更多场馆</h1>
              <p>MORE VENUES</p>
            </div>
            <div class="right-p_two">
              <h1>进入游戏</h1>
              <p>ENTER GAME</p>
            </div>
          </div>-->
        </div>
      </div>

      <div class="setion2-content" v-if="titleIndex == 4">
        <div class="setion2-letbox">
          <img src="@/assets/home/namelet.png" class="top1" />
          <img src="@/assets/home/namelet5.png" class="top2" />
          <p>
            亚洲领先的产品研发机构,丰富的体育赛事,项目玩法多元,倾力打造的刺激火爆体育娱乐游戏,
            下半场波胆、让球崭新玩法,最全赛事覆盖助你花式收米赢到人生巅峰。
          </p>
        </div>
        <div class="setion2-conterbox">
          <img src="@/assets/home/ty_center.png" class="ty_logo" />
        </div>
        <div class="setion2-rightbox">
          <img src="@/assets/home/ty_bg5.png" />

          <div class="more">
            <div class="right-p">
              <h1>更多场馆</h1>
              <p>MORE VENUES</p>
            </div>
            <div class="right-p_two" @click="SetRouter('Sport')">
              <h1>进入游戏</h1>
              <p>ENTER GAME</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 优质服务 -->
    <div class="title2-box">
      <div class="title2-contnent">
        <div class="title3-img"></div>
      </div>
    </div>

    <div class="setbox">
      <div class="setbox-contnent">
        <div class="contentone-setion">
          <div class="li-box" v-for="(items, indeexs) in footerlist" :key="indeexs">
            <div class="setbox-bg">
              <h1>{{ items.num }}</h1>
              <span>{{ items.spanp }}</span>
            </div>
            <p>{{ items.trp }}</p>
          </div>
        </div>

        <div class="set-bottombox">
          <div class="bottom-contentbg" v-for="(itef, indexf) in bottomlist" :key="indexf">
            <img  v-lazy="itef.Image" class="bottomiconlet" />
            <div class="bottomtext">
              <h1>{{ itef.text }}</h1>
              <p>{{ itef.textp }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbanners, getnotioes  } from "@/http/api.js";

export default {
  name: "Index",
  data() {
    return {
      bannerslist: [],
      tetx_active: 0, // 安全畅快 实时投注 -> 索引
      titleIndex: 0, // 热门游戏  -> 索引

      titledata: [
        {
          ID: 0,
          txt: "电子",
        },
        {
          ID: 1,
          txt: "真人",
        },
        {
          ID: 2,
          txt: "捕鱼",
        },
        {
          ID: 3,
          txt: "彩票",
        },
        {
          ID: 4,
          txt: "体育",
        },
      ],
      bottomlist: [
        {
          id: 1,
          text: "极速存取转款",
          textp:
            "最新技术自主研发的财务处理系统真正做到极速存、取、转独家网络优化技术，为您提供一流的游戏体验，最大优化网络延迟。",
          Image: require("@/assets/home/bottom-icon1.png"),
        },
        {
          id: 2,
          text: "海量赛事种类",
          textp: "每天为您提供近千场精彩体育赛事，更有真人、彩票、电子游戏等多种娱乐管道选择，让您拥有完美游戏体验。",
          Image: require("@/assets/home/bottom-icon2.png"),
        },
        {
          id: 3,
          text: "加密安全管理",
          textp:
            "独家开发，采用128比特加密技术和严格的安全管理体系，客户资金得到最完善的保障，让您全情尽享娱乐、赛事投注、无后顾之忧！",
          Image: require("@/assets/home/bottom-icon3.png"),
        },
        {
          id: 4,
          text: "三端任您选择",
          textp:
            "引领市场的卓越科技，自主研发了全套终端应用，让您随时随地，娱乐投注随心所欲！ 7x24小时线上客服提供最贴心、最优质的服务。",
          Image: require("@/assets/home/bottom-icon4.png"),
        },
      ],

      listy: [
        {
          id: 1,
          title: "全站APP",
          textp:
            "全球首家一体化娱乐原生APP，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有!",
          let_img: require("@/assets/home/setion1-let.png"),
        },
        {
          id: 2,
          title: "体育APP",
          textp:
            "业内赔率最高！ 覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。 更有动画直播，让您体验轻松聊球，娱乐投注两不误。",
          let_img: require("@/assets/home/setion2-let.png"),
        },
        {
          id: 3,
          title: "棋牌APP",
          textp:
            "皇冠体育集团官方直营，欧洲最高级别安全认证，热门棋牌品类丰富，聆听悦耳音乐，感受非同凡响极致体验，让游戏改变生活，尽在亚博棋牌。",
          let_img: require("@/assets/home/setion3-let.png"),
        },
      ],

      footerlist: [
        {
          num: 30,
          trp: "平均存款时间",
          spanp: "秒",
        },
        {
          num: 50,
          trp: "平均取款时间",
          spanp: "秒",
        },
        {
          num: 28,
          trp: "合作支付平台",
          spanp: "家",
        },
        {
          num: 32,
          trp: "平均游戏平台",
          spanp: "家",
        },
      ],
      notionesText: null,
    };
  },
  methods: {
    // 安全畅享
    change(index) {
      this.tetx_active = index;
    },
    // 热门游戏
    Titlechange(i) {
      this.titleIndex = i;
    },

    async Bernnes() {
      try {
        let {data:data1} = await getbanners();
        let {data:data} = await getnotioes();
        this.bannerslist = data1;
        this.notionesText = data[0].content;
      } catch (error) {
        console.log(error.message);
      }
    },

    SetRouter(val){
      this.$router.push(val)
    },

  },
  mounted() {
    this.Bernnes();
  },
};
</script>

<style src="@/styles/homes.less" lang="less" scoped></style>

<template>
    <div class="setion-box">
      <div class="wrapperbox">
        <img v-for="(item,i) in imgList"  :key="i" v-lazy="item" />
      </div>

      <div class="wrapperbox-two">
        <div class="brand">
          <img v-lazy="require('@/assets/footer/mibos.png')" class="minos-icon" />
          <p>LeYuGAME由MI平台(台湾)</p>
          <p>包网团队提供平台技术支援</p>
          <div class="br-box">
            <span>商务合作</span>
            <img v-lazy="require('@/assets/footer/skype1.png')" />
            <img v-lazy="require('@/assets/footer/skype2.png')" />
            <img v-lazy="require('@/assets/footer/skype3.png')" />
          </div>
        </div>

        <div class="brand-two">
          <div class="brand-twocontent">
            <div class="img-bg-box" v-for="(item,k) in bottomImg" :key="k">
              <img v-lazy="item.bg_img" />
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>

        <div class="lianxibox">
          <p v-for="(item,index) in list " :key="index" @click="getAbouts(item.id)">{{item.title}}</p>
        </div>

        <div class="kefubox">
          <div class="kefucontent">
            <div class="boxstetion">
              <div class="top">
                <img v-lazy="require('@/assets/footer/zaixian-kefu.png')" />
                <span>线上客服</span>
              </div>
              <div class="btn" @click="getSeives">立即咨询</div>
            </div>
            <div class="boxstetion">
              <div class="top">
                <img v-lazy="require('@/assets/footer/line.png')" />
                <span>LINE</span>
              </div>
              <div class="btn" @click="getSeives">立即咨询</div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-ptextbox">
        <p>
          LeYuGAME拥有欧洲马尔他MGA、英国GC监督委员会(GamblingCommission)和菲律宾政府(PAGCOR)颁发的合法执照。注册于英属维京群岛,
          是受国际行业协会认可的合法公司。进行注册并娱乐前,请确保您年满18周岁!
        </p>
        <p>版权所有 ©2019-2023 LeYuGAME 保留所有权</p>
      </div>

      

      <div class="peifedbottom" v-show="$store.state.isFanshui" @click="setroute">
      <i class="el-icon-circle-close close-icon" @click="Close"></i>
          <span>点击领取</span>
      </div>



      
      <el-dialog :visible.sync="alartVisible" top="8vh" width="600px"  :close-on-press-escape="false"
      :close-on-click-modal="false" class="dailog_bg" :show-close="false" :modal="false">
      <i class="el-icon-close alart-close" @click="alartVisible = false"></i>
        <el-carousel :interval="5000" arrow="never" height="824px" class="swiper_box">
        <el-carousel-item v-for="(item,i) in alrtImg" :key="i" class="swiper_item">
         <img :src="item.content" alt="" srcset="">
        </el-carousel-item>
      </el-carousel>
      </el-dialog>



      <div class="redBox" v-if="showRed">
        <i class="el-icon-error left-iocn" @click="showRed = false"></i>
        <img src="@/assets/Red/aside-Redpng.png" class="redPge" @click="getRouteRed">
      </div>


      <div class="panBox" v-if="showPan">
        <i class="el-icon-error rifht-iocn" @click="showPan = false"></i>
        <img src="@/assets/asideAdv.gif" class="redPge" @click="getRoutePan">
      </div>


    </div>

</template>
<script>

import {Fangshui , Aboutlist , getnotioes , getConfig} from '@/http/api'
export default {
  data() {
    return {
      alartVisible:false,
      showRed:false,
      showPan: false,
      list:[],
      imgList:[
        require('@/assets/footer/footer-logo1.png'),
        require('@/assets/footer/footer-logo2.png'),
        require('@/assets/footer/footer-logo3.png'),
        require('@/assets/footer/footer-logo4.png'),
        require('@/assets/footer/footer-logo5.png'),
        require('@/assets/footer/footer-logo6.png'),
        require('@/assets/footer/footer-logo7.png'),
        require('@/assets/footer/footer-logo8.png'),
        require('@/assets/footer/footer-logo9.png'),
        require('@/assets/footer/footer-logo10.png')
      ],

      bottomImg:[
        {
          text:'马尔他牌照(MGA)认证',
          bg_img: require('@/assets/footer/svg2.png')
        },
        {
          text:'英属维京群岛(BVI)认证',
          bg_img: require('@/assets/footer/svg4.png')
        },
        {
          text:'英国GC监督委员会',
          bg_img: require('@/assets/footer/svg1.png')
        },
        {
          text:'菲律宾(PAGCOR)监督牌照',
          bg_img: require('@/assets/footer/svg3.png')
        }
      ],
      alrtImg:[]
    };
  },
  methods:{
    async Backwater(){
      try {
        let {data:arr} = await Fangshui()
        let relust = 0
        for (let index = 0; index < arr.length; index++) {
           relust += arr[index].fs_money
        }
        let num = parseFloat(relust.toFixed(2))
        if(arr.length > 0){
          this.$notify({
          title: '温馨提示',
          message: '您还有反水' + num + '元待领取！',
          type: 'success',
          showClose: false,
          offset: 80,
          duration: 6000
         }); 
         this.$store.commit('SET_ISFANGSHUI', true)
        }
      } catch (error) {
        console.log(error.message);
      }
    },


    configInfo(){
      let li_nk = window.location.host

      getConfig(li_nk,'activity').then(res=>{
       if(res.data.is_redbag_open !== 0 && window.localStorage.getItem('token')){
        this.showRed = true
       }

       if(res.data.activity_wheel_is_open !== 0 &&  window.localStorage.getItem('token')){
        this.showPan = true
       }



      }).catch(err=>{
        console.log(err.message);
      })
    },

    // 跳转红包
    getRouteRed(){
      this.$router.push('/Red')
    },

    getRoutePan(){
      this.$router.push('/zhuanpan')
    },

    getSeives(){
      let link = window.localStorage.getItem('link')
      window.open(link, '_blank', 'width=1100,height=900')
    },

    async SetAboutlist(){
      try {
        let res= await Aboutlist()
        let red = await getnotioes()
        this.alrtImg = red.alert
        if( this.alrtImg.length > 0){
          this.alartVisible = true
        }
        this.list = res.data
      } catch (error) {
        console.log(error);
      }
    },

    getAbouts(i){
      this.$router.push({name:'AboutUs',params:{id:i}})
    },

    setroute(){
      this.$router.push({path:'/Member/Transfer',query:{id:'1'}})
    },

    Close(){
      this.$store.commit('SET_ISFANGSHUI', false)
    }

  },
  watch:{
    '$store.state.islogin'(val){ 
     if(val == '1'){
       this.configInfo()
       this.Backwater()
     }
    },
    '$route'(to){
      to.path == '/' && this.$store.state.islogin == '1' ? this.Backwater() :''
    }
  },
  mounted(){
    this.SetAboutlist()
    this.configInfo()
  }  
};
</script>
<style lang="less">
.setion-box {
  width: 100%;
  height: 330px;
  background-color: #29283e;

  .wrapperbox {
    width: 1350px;
    height: 70px;
    margin: 0 auto;
    border-bottom: 1px solid #4e5665;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: auto;
      height: 42px;
      cursor: pointer;
      &:hover {
        -webkit-filter: brightness(200%);
        filter: brightness(200%);
      }
    }
  }

  /* 第二个盒子 */
  .wrapperbox-two {
    width: 1350px;
    height: 180px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 133px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand {
      width: 160px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      font-size: 12px;
      color: white;
      .minos-icon {
        width: 74px;
        height: 21px;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
            -webkit-filter: brightness(200%);
            filter: brightness(200%);
          }
      }
      .br-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
          &:hover {
            -webkit-filter: brightness(200%);
            filter: brightness(200%);
          }
        }
      }
    }

    /* 第二个盒子 --> 中间 */
    .brand-two {
      width: 410px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .brand-twocontent {
        width: 100%;
        height: 124px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .img-bg-box {
          width: 176px;
          height: 62px;
          color: white;
          font-size: 12px;
          img {
            width: auto;
            height: 41px;
            cursor: pointer;
            &:hover {
              -webkit-filter: brightness(200%);
              filter: brightness(200%);
            }
          }
        }
      }
    }

    .lianxibox {
      width: 60px;
      height: 100%;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #828e9e;
      cursor: pointer;
      p:hover {
        color: white;
      }
    }

    .kefubox {
      width: 71px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .kefucontent {
        width: 100%;
        height: 118px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        .boxstetion {
          width: 100%;
          height: 58px;
          font-size: 12px;
          color: white;
          .top {
            width: 100%;
            height: 17px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5px;
            img {
              width: 17px;
              height: 17px;
              margin-right: 2px;
            }
          }
          .btn {
            width: 100%;
            height: 26px;
            border: 1px solid #707684;
            border-radius: 5px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
            &:hover {
              border-color: white;
            }
          }
        }
      }
    }
  }

  /* 页脚 */
  .footer-ptextbox {
    width: 1350px;
    height: 52px;
    margin: 0 auto;
    font-size: 14px;
    color: white;
    text-align: center;
  }




  // 反水弹窗
  .peifedbottom{
    width: 280px;
    height: 280px;
    // border-radius: 32px;
    margin: 0 auto;
    position: fixed;
    right: 70px;
    bottom: 60px;
    cursor: pointer;
    text-align: center;
    line-height: 240px;
    font-size: 18px;
    color: white;
		animation-name: movebottom , wobble ;
		animation-duration: 3s , 2s;
		animation-timing-function: linear , cubic-bezier(0.22, 0.61, 0.36, 1);
		animation-delay: 0s, 3s;
		animation-iteration-count: 1,infinite;
		animation-direction: normal;
		animation-fill-mode: forwards;
    background-image: url('@/assets/h_bao.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 999999999;

    .close-icon{
      position: absolute;
      font-size: 28px;
      right:26px;
      top: -26px;
      color: #fc4524;
      transition: all 1s ease;
      &:hover{
        transform: rotate(360deg);
      }
    }

    span{
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &:hover span{
      opacity: 1;
    }
  }


  @keyframes movebottom {
		0% {
			transform:translateX(600px);
			animation-timing-function:ease-in;
			opacity:0;
		}
		35% {
			transform:translateX(0);
			animation-timing-function:ease-out;
			opacity:1;
		}
		55% {
			transform:translateX(48px);
			animation-timing-function:ease-in;
		}
		70% {
			transform:translateX(0);
			animation-timing-function:ease-out;
		}
		80% {
			transform:translateX(32px);
			animation-timing-function:ease-in;
		}
		90% {
			transform:translateX(0);
			animation-timing-function:ease-out;
		}
		95% {
			transform:translateX(8px);
			animation-timing-function:ease-in;
		}
		100% {
			transform:translateX(0);
			animation-timing-function:ease-out;
		}
  }


  @keyframes wobble {
    0% {
			transform:translateY(0);
		}
		25% {
			transform:translateY(0);
		}
		40% {
			transform:translateY(-8px);
		}
		55% {
			transform:translateY(0);
		}
		65% {
			transform:translateY(-8px);
		}
		75% {
			transform:translateY(0);
		}
		82% {
			transform:translateY(-6px);
		}
		87% {
			transform:translateY(0);
		}
		93% {
			transform:translateY(-4px);
		}
		100% {
			transform:translateY(0);
		}
  }
}



.dailog_bg .el-dialog{
  background-color: transparent !important;
}

.dailog_bg .el-dialog__header{
  display: none;
}
.dailog_bg .el-dialog__body{
  padding: 0;
  // position: relative;
}

// .swiper_box{
//   height: 824px;
// }


.alart-close{
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 8;
  font-size: 28px;
  color: #fff;
  cursor: pointer;
}

.redBox{
  width: 200px;
  height: 200px;
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 9;
  animation: moveup 1.5s ease infinite;
  -webkit-animation: moveup 1.5s ease infinite;
}



.panBox{
  width: 200px;
  height: 200px;
  position: fixed;
  bottom: 60px;
  left: 10px;
  z-index: 9;
}

.rifht-iocn{
  position: absolute;
  right: -18px;
  top: 0px;
  font-size: 26px;
  cursor: pointer;
}


.redPge{
  width: 190px;
  height: 180px;
  cursor: pointer;
}


.left-iocn{
  position: absolute;
  left: -45px;
  top: -4px;
  font-size: 26px;
  cursor: pointer;
}


@keyframes moveup {
  50%{
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
  }

  100%{
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }

}
  
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/zh-TW'
import locale from 'element-ui/lib/locale'
import Clipboard from  'vue-clipboard2'
import VueLazyload from 'vue-lazyload'


// 设置语言
locale.use(lang)
Vue.use(ElementUI)
Vue.use(Clipboard)
Vue.use(VueLazyload)


Vue.config.productionTip = false



//防抖处理-立即执行
const on = Vue.prototype.$on
Vue.prototype.$on = function(event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === 'click') {
    newFunc = function() {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function() {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

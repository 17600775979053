import service from '.'

// 首页轮播图
export function getbanners() {
  return service({
    url: '/banners',
    method: 'GET',
    params: {
      group: 'new1',
      lang: 'zh_cn',
    },
    ShowLoading: true,
  })
}

// 公告
export function getnotioes() {
  return service({
    url: '/system/notices',
    method: 'GET',
    params: {
      lang: 'zh_cn',
    },
    ShowLoading: true,
  })
}

// 登录注册图形验证码
export function getcaptcha() {
  return service({
    url: '/auth/captcha',
    method: 'GET',
    params: {},
    ShowLoading: false,
  })
}

// 登录
export function getLogin(fromdata) {
  return service({
    url: '/auth/login',
    method: 'POST',
    data: fromdata,
    ShowLoading: true,
  })
}

// 註冊
export function getRegister(frata) {
  return service({
    url: '/auth/register',
    method: 'POST',
    data: frata,
    ShowLoading: true,
  })
}

// 验证码验证
export function getbankcheck(num, kes) {
  return service({
    url: '/auth/check',
    method: 'GET',
    params: {
      tn_r: num,
      key: kes,
    },
    ShowLoading: true,
  })
}


// 短信驗證碼
export function getchecksms(num) {
  return service({
    url: '/auth/sms?lang=' + 'zh_cn',
    method: 'POST',
    data: {
      phone: num
    },
    ShowLoading: true,
  })
}


// 忘记密码
export function getPassword(froms) {
  return service({
    url: '/auth/reset_pass?lang=' + 'zh_cn',
    method: 'POST',
    data: froms,
    ShowLoading: true,
  })
}





// 退出
export function logout() {
  return service({
    url: '/auth/logout',
    method: 'POST',
    data: {
      lang: 'zh_cn',
    },
    ShowLoading: true,
  })
}




// 首页热门游戏
export function mainGame(i) {
  return service({
    url: '/main/hotgame',
    method: 'GET',
    params: {
      type:i,
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}



// 获取用户信息
export function getme() {
  return service({
    url: '/auth/me',
    method: 'POST',
    ShowLoading: false,
  })
}


//  客服,注册,窗口
export function getConfig(url,group) {
  return service({
    url: '/system/configs',
    method: 'GET',
    params: {
      lang: 'zh_cn',
      url: url,
      group: group
    },
    ShowLoading: true,
  })
}

// 获取游戏标题
export function getgames() {
  return service({
    url: '/games',
    method: 'GET',
    params: {
      lang: 'zh_cn',
    },
    ShowLoading: true,
  })
}

// 获取游戏列表
export function getgamelist(type) {
  return service({
    url: '/games/apis',
    method: 'GET',
    params: {
      lang: 'zh_cn',
      gameType: type,
    },
    ShowLoading: true,
  })
}


// 获取搜索游戏列表
export function Selectgame(type,name) {
  return service({
    url: '/games/lists',
    method: 'GET',
    params: {
      lang: 'zh_cn',
      gameType: type,
      tag:'',
      keyword: name,
      limit: 50
    },
    ShowLoading: true,
  })
}



// 资金回收
export function getrecovery() {
  return service({
    url: '/game/recovery_last',
    method: 'POST',
    ShowLoading: false,
  })
}

// 账号详情页面进来
export function getgamemoneys() {
  return service({
    url: '/game/api_moneys?lang=' + 'zh_cn',
    method: 'POST',
    ShowLoading: true,
  })
}

// 切换手动自动
export function gettrans(id) {
  return service({
    url: 'game/change_trans',
    method: 'POST',
    data: {
      status: id,
    },
    ShowLoading: true,
  })
}

// 我的信息
export function getmessage() {
  return service({
    url: '/member/message/list',
    method: 'POST',
    ShowLoading: true,
  })
}

// VIP中心
export function getVip() {
  return service({
    url: '/member/vips',
    method: 'GET',
    params: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}

// 银行卡类型
export function getbanktype() {
  return service({
    url: '/member/bank/type',
    method: 'POST',
    ShowLoading: true,
  })
}

//推广代理
export function gettuidai() {
  return service({
    url: '/abouts',
    method: 'GET',
    params: {
      type: 5,
      lang: 'zh_cn',
    },
    ShowLoading: false,
  })
}

// 优惠活动
export function getactivities() {
  return service({
    url: '/activities',
    method: 'GET',
    params: {
      lang: 'zh_cn',
    },
    ShowLoading: true,
  })
}

// 活动详情
export function getactivity(id) {
  return service({
    url: '/activity/' + id,
    method: 'GET',
    params: {
      lang: 'zh_cn',
    },
    ShowLoading: false,
  })
}

//获取游戏类型
export function getType() {
  return service({
    url: '/game/type?lang=' + 'zh_cn',
    method: 'POST',
    ShowLoading: true,
  })
}

// 获取游戏记录
export function getgamerecord(val) {
  return service({
    url: '/game/record?lang=' + 'zh_cn',
    method: 'POST',
    data: val,
    ShowLoading: true,
  })
}

// 交易記錄
export function getmoneylog(data) {
  return service({
    url: '/moneylog?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}

//优惠详情
export function Youhuidateil(id) {
  return service({
    url: '/act/' + id + '?lang=' + 'zh_cn',
    method: 'GET',
    params: {},
    ShowLoading: true,
  })
}

//优惠活动提交
export function Sumitspecial(data,id) {
  return service({
    url: '/act/apply/' + id + '?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}

//获取优惠活动查询项目
export function queryActivity(data) {
  return service({
    url: '/act/list' + '?lang=' + 'zh_cn',
    method: 'GET',
    data: data,
    ShowLoading: true,
  })
}


//获取查询项目列表
export function Applyresult(data) {
  return service({
    url: '/act/apply/result?lang='+ 'zh_cn',
    method: 'GET',
    params: data,
    ShowLoading: true,
  })
}

// 银行卡类型
export function getBanktype() {
  return service({
    url: '/member/bank/type?lang=' + 'zh_cn',
    method: 'GET',
    ShowLoading: true,
  })
}

// 银行卡
export function getBank() {
  return service({
    url: '/member/bank?lang=' + 'zh_cn',
    method: 'GET',
    ShowLoading: true,
  })
}

//銀行卡提交
export function SubmitBank(bankdata) {
  return service({
    url: '/member/bank?lang=' + 'zh_cn',
    method: 'POST',
    data: bankdata,
    ShowLoading: true,
  })
}



//銀行卡修改
export function BiangengBank(bankdata, id) {
  return service({
    url: '/member/bank/' + id + '?lang=' + 'zh_cn',
    method: 'PATCH',
    data: bankdata,
    ShowLoading: true,
  })
}

//反水
export function Fangshui() {
  return service({
    url: '/fsnow/list?lang=' + 'zh_cn',
    method: 'GET',
    params: {},
    ShowLoading: true,
  })
}

//反水领取
export function fangling(time) {
  return service({
    url: '/fsnow/fetch?lang=' + 'zh_cn',
    method: 'POST',
    data: {
      deadtime: time
    },
    ShowLoading: true,
  })
}


// 反水金额表
export function Fanglist(type) {
  return service({
    url: '/fs/levels',
    method: 'GET',
    params: {
      lang: 'zh_cn',
      game_type: type
    },
    ShowLoading: true,
  })
}

//余额宝专区 =>方案
export function yuebaoZone(id) {
  return service({
    url: 'yuebao/getMemberYuebaoList' + '?lang=' + 'zh_cn',
    method: 'POST',
    params: {},
    ShowLoading: true,
  })
}

//取款页面提交
export function Qukuan(Fdata) {
  return service({
    url: '/drawing' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: Fdata,
    ShowLoading: true,
  })
}

// 获取站内用户消息
export function getmessgae(pageid) {
  return service({
    url: '/member/message/list' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: {
      page: pageid,
    },
    ShowLoading: true,
  })
}

// 推广赚钱 =>账号查询
export function Accountinquiry(data) {
  return service({
    url: '/team/childlist' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}

// 推广赚钱 -> 业绩查询
export function Perforquiry(data) {
  return service({
    url: '/team/performanceDetail' + '?lang=' + 'zh_cn',
    method: 'GET',
    params: data,
    ShowLoading: true,
  })
}

// 推广赚钱 -> 我的推廣
export function Mypromotion(data) {
  return service({
    url: '/member/agent' + '?lang=' + 'zh_cn',
    method: 'GET',
    params: data,
    ShowLoading: true,
  })
}

//我的推广详情
export function promoDetails(data) {
  return service({
    url: '/team/report' + '?lang=' + 'zh_cn',
    method: 'Post',
    params: data,
    ShowLoading: true,
  })
}

// 反馈问题信息列表
export function Feedbackmsglist(pages) {
  return service({
    url: '/member/message/send_list' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: {
      page: pages,
    },
    ShowLoading: true,
  })
}

// 意见反馈
export function feedBack(data) {
  return service({
    url: '/member/message/send' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}


//  存款 -> 支付通道
export function Paymentlist() {
  return service({
    url: '/payment/normal/list' + '?lang=' + 'zh_cn',
    method: 'GET',
    ShowLoading: true,
  })
}


// 存款 -> 提交
export function subDeposit(fromdata) {
  return service({
    url: '/recharge/normal' + '?lang=' + 'zh_cn',
    method: 'POST',
    data: fromdata,
    ShowLoading: true,
  })
}


//  进入游戏
export function Gogome(code) {
  return service({
    url: '/game/login' + '?lang=' + 'zh_cn',
    method: 'GET',
    params: code,
    ShowLoading: false,
  })
}

// 获取游戏金额
export function gameMoeny(apicode) {
  return service({
    url: '/game/api_money',
    method: 'POST',
    data: {
      lang: 'zh_cn',
      api_code: apicode
    },
    ShowLoading: true,
  })
}

//  转入游戏金额
export function zhuangameMoeny(apicode, moneny) {
  return service({
    url: '/game/deposit',
    method: 'POST',
    data: {
      lang: 'zh_cn',
      api_code: apicode,
      money: moneny,
      money_type: "money"
    },
    ShowLoading: false,
  })
}


// 我的收藏
export function getfavor(type) {
  return service({
    url: '/favor/list',
    method: 'GET',
    params: {
      lang: 'zh_cn',
      game_type: type
    },
    ShowLoading: true,
  })
}


// 取消收藏
export function Deletefavor(FArray) {
  return service({
    url: '/favor/delete?lang=' + 'zh_cn',
    method: 'POST',
    data: FArray,
    ShowLoading: true,
  })
}



// 加入收藏
export function Addfavor(arrayss) {
  return service({
    url: '/favor/add?lang=' + 'zh_cn',
    method: 'POST',
    data: arrayss,
    ShowLoading: true,
  })
}


// 申請代理
export function Applyagent(datas) {
  return service({
    url: '/agent/apply?lang=' + 'zh_cn',
    method: 'POST',
    data: datas,
    ShowLoading: true,
  })
}


// 关于我们列表
export function Aboutlist() {
  return service({
    url: '/about/list?lang=' + 'zh_cn',
    method: 'GET',
    ShowLoading: true,
  })
}


// 关于我们详情
export function Aboutdateil(i) {
  return service({
    url: '/abouts',
    method: 'GET',
    params: {
      id: i,
      lang: 'zh_cn'
    },
    ShowLoading: false,
  })
}


// 签到 
export function getDailybonuscheck() {
  return service({
    url: '/dailybonus/check',
    method: 'POST',
    data: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}



// 获取签到列表
export function getDailybonus() {
  return service({
    url: '/dailybonus/history',
    method: 'GET',
    params: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}

// 打卡奖励历史记录
export function getAwardhistory() {
  return service({
    url: '/dailybonus/award/history',
    method: 'GET',
    params: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}


// 打卡奖励列表
export function getAwardlist() {
  return service({
    url: '/dailybonus/award/list',
    method: 'GET',
    params: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}


// 打卡奖励领取
export function SetAward() {
  return service({
    url: '/dailybonus/64/award?lang='+'zh_cn',
    method: 'POST',
    ShowLoading: true,
  })
}

// 站内信信息删除
export function deleteMsg(data) {
  return service({
    url: '/member/message/delete?lang=' + 'zh_cn',
    method: 'DELETE',
    data:data,
    ShowLoading: true,
  })
}



// 站内信信息一键删除
export function deleteAll() {
  return service({
    url: '/member/message/delete_all',
    method: 'DELETE',
    params: {
      lang: 'zh_cn'
    },
    ShowLoading: true,
  })
}


//  个人资料绑定
export function Updateinfo(data) {
  return service({
    url: '/auth/info/update?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}

// 修改密碼
export function Uppassword(data) {
  return service({
    url: '/member/password/modify?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}


// 修改取款密碼
export function UpDrawPassword(data) {
  return service({
    url: '/member/drawing_pwd/modify?lang=' + 'zh_cn',
    method: 'POST',
    data: data,
    ShowLoading: true,
  })
}


// 紅包規則
export function getRedrolus() {
  return service({
    url: '/redbag/desc',
    method: 'GET',
    params: {
      lang:'zh_cn'
    },
    ShowLoading: true,
  })
}


// 紅包記錄
export function getRedLog(num) {
  return service({
    url: '/redbag/log?lang='+ 'zh_cn',
    method: 'POST',
    data:{
			limit: 3,
			page: num
		},
    ShowLoading: true
  })
}


// 提交紅包
export function getRed(num) {
  return service({
    url: '/activity/redbag?lang=' + 'zh_cn',
    method: 'POST',
    data:{
			times: num
		},
    ShowLoading: true
  })
}

// 快捷登录
export function getkuaiLogin(val) {
  return service({
    url: '/auth/phone_login',
    method: 'POST',
    data:val,
    ShowLoading: true
  })
}

// 转盘设置
export function weollSeting(val) {
  return service({
    url: '/wheel/setting',
    method: 'GET',
    params:{
      lang:'zh_cn'
    },
    ShowLoading: true
  })
}


// 转盘次数查询
export function weollSelect(val) {
  return service({
    url: '/wheel/query',
    method: 'POST',
    data:{
      name: val
    },
    ShowLoading: true
  })
}


// 转盘抽奖 
export function getChoujiang(val) {
  return service({
    url: '/wheel/award',
    method: 'POST',
    data:{
      name: val
    },
    ShowLoading: true
  })
}

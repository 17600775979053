<template>
  <div>

    <el-dialog
      width="270px"
      top="30vh"
      :lock-scroll="false"
      custom-class="chapca"
      :visible.sync="$store.state.chackCaptcha"
      append-to-body
      destroy-on-close
      :close-on-click-modal="false"
      @open="openDialog"
      @close="closeOut">
      
      <div class="headertop" slot="title">
          <span>拖动下方滑块完成拼图</span>
          <i class="el-icon-refresh" @click="setChatcha"></i>
        </div>

      <div class="img_bg">
        <div class="loadding-bg" v-show="isLoadding">加载中...</div>
        <div class="bg" :style="'background-image:' + img1"></div>
        <div class="bg" :style="'background-image:' + img2 + ';' + 'left:' + Left + 'px;' + 'z-index:'+ 5"></div>
        <div class="bg bg3" :style="'background-image:' + img3 + ';' + 'display:' + movestatus"></div>
      </div>

      <div class="yangzheng">
        <div class="success" :style="'width:' + Left + 'px'"></div>
        <div class="huakuai"  @mousedown="sliderDown" :style="'left:' + Left + 'px'">
          <i class="iconright" :class="movestatus == 'black' ? 'el-icon-check' : 'el-icon-right'"></i>
        </div>
      </div>

      <div class="bottom_tet" v-show="messages !== ''">
        {{ messages }}
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getcaptcha, getbankcheck} from '@/http/api'
export default{
  data(){
    return{
      img1: '',
      img2: '',
      img3: '',
      Left: 0,
      initX: 0,
      movestatus: 'none',
      messages: '',
      cun_key: '', // 存入图形验证码的key 值
      yanzhengma: false, // 验证码弹窗
      isLoadding: false, // 图形码 -> 加载中...
    }
  },
  methods:{
    
    // 获取 验证码图片
    async setChatcha() {
      this.isLoadding = true
      let res = await getcaptcha()
      try {
        if (res.code == 200) {
          this.isLoadding = false
          this.cun_key = res.key
          let img = new Image()
          img.src = res.data
          let that = this
          img.onload = function () {
            that.changeImage(img, src => {
              that.img2 = 'url(' + src + ')'
            })
          }
          that.img1 = 'url(' + res.data + ')'
          that.img3 = 'url(' + res.data + ')'
        }
      } catch (error) {
        console.log(error)
      }
    },

    openDialog(){
      this.$nextTick(()=>{
        this.setChatcha()
        this.movestatus = 'none'
        this.messages = ''
      })
    },

    // 验证码图片剪
    changeImage(img, callback) {
      var canvas = document.createElement('canvas')
      canvas.width = 50
      canvas.height = img.height / 3
      var ctx_mark = canvas.getContext('2d')
      ctx_mark.clearRect(0, 0, 50, canvas.height)
      ctx_mark.drawImage(img, 0, 150, 50, 150, 0, 0, 50, 150)
      var imageData = ctx_mark.getImageData(0, 0, 50, 150)
      var data = imageData.data
      var x = canvas.height,
        y = canvas.width
      for (let j = 0; j < x; j++) {
        let ii = 1,
          k1 = -1
        for (let k = 0; k < y && k >= 0 && k > k1; ) {
          let i = (j * y + k) * 4
          k += ii
          let r = data[i],
            g = data[i + 1],
            b = data[i + 2]
          if (r + g + b < 200) data[i + 3] = 0
          else {
            let arr_pix = [1, -5]
            let arr_op = [250, 0]
            for (let i = 1; i < arr_pix[0] - arr_pix[1]; i++) {
              let iiii = arr_pix[0] - 1 * i
              let op = parseInt(arr_op[0] - ((arr_op[0] - arr_op[1]) / (arr_pix[0] - arr_pix[1])) * i)
              let iii = (j * y + k + iiii * ii) * 4
              data[iii + 3] = op
            }
            if (ii == -1) {
              break
            }
            k1 = k
            k = y - 1
            ii = -1
          }
        }
      }
      ctx_mark.putImageData(imageData, 0, 0)
      callback(canvas.toDataURL('image/png'))
    },

     // 验证码 移动 -> 结束处理函数
    sliderDown(e){
      let initX = e.clientX
      document.onmousemove = (event)=>{
        let moveX = event.clientX
        this.Left = moveX - initX
        this.Left >= 190 ? this.Left = 190 : ''
        this.Left <= 0 ? this.Left = 0 : ''
        event.preventDefault();
      }

      document.onmouseup = ()=> {
        document.onmousemove = null;
        document.onmouseup = null;
        console.log('已停止移动')
        getbankcheck(this.Left, this.cun_key).then((msg)=>{

          if(msg == 'ok'){
              this.$store.commit('SET_KEY',this.cun_key)
              this.movestatus = 'black'
  
               // 延迟一秒关闭
               setTimeout(() => {
                this.$store.dispatch('ChangeCaptcha',false)
                this.Left = 0
              }, 1000)

          }else{
            this.Left = 0
            this.messages = '验证失败'
          }


        }).catch((err)=>{
          console.log(err);
        })
      }
    },


    // 验证码弹窗关闭时 -> 清除监听
    closeOut() {
      this.$store.dispatch('ChangeCaptcha',false)
      window.document.onmousemove = null
      window.document.onmouseup = null
    },

  },

}
</script>


<style lang="less" scoped>


::v-deep .chapca .el-dialog__header {
  padding: 10px 15px;
}

::v-deep .chapca .el-dialog__body {
  padding: 0px 15px 10px;
}


::v-deep .chapca .el-dialog__headerbtn{
  font-size: 20px;
  top: 5px;
  right: 5px;
  transition: all 1s cubic-bezier(0.39, 0.58, 0.57, 1);
  &:hover {
    transform: rotate(360deg);
  }
}


.headertop{
  .el-icon-refresh {
    font-size: 20px;
    color: #555555;
    cursor: pointer;
    margin-left: 5px ;
    vertical-align: middle;
    &:hover{
      color: #4e9cf1;
    }
  }
}



.img_bg {
  width: 240px;
  height: 150px;
  position: relative;

  .loadding-bg {
    width: 100%;
    height: 100%;
    line-height: 150px;
    text-align: center;
    background-color: #909399;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
  }

 

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-repeat: no-repeat;
  }
  .bg3 {
    z-index: 6;
    background-position: 0 -300px;
  }
}

.yangzheng {
  width: 100%;
  height: 40px;
  margin: 10px 0 0px;
  position: relative;
  background-color: #dcdfe6;
  box-shadow: inset 0 0 5px #b3b3b3;

  .success {
    height: 40px;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #31e49f;
  }


  .huakuai {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #4e9cf1;
    box-shadow: 0 0 5px #4e9cf1;

    .iconright {
      font-size: 18px;
      color: white;
    }
  }
}

.fot_msg {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}


.refresh_active {
  transform: rotate(360deg);
  transition: transform 0.6s ease;
}


.bottom_tet{
  width: 100%;
  line-height: 26px;
  height: 20px;
  text-align: center;
  -webkit-animation: slideright 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: slideright 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}


@keyframes slideright {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}


</style>
import axios from "axios";
import { Loading , Message } from 'element-ui';
import store from '@/store/index'
import route from '@/router/index'




let loading = null
let needLoadingRequestCount = 0

function startLoading(){
    loading = Loading.service({
        lock: true,
        text:'加载中...',
        fullscreen: true,
        background:'rgba(0,0,0,0.7)'
    })
}

function endLoading(){
    loading.close()
}

function showfullLoading(){
    startLoading()
    needLoadingRequestCount++
}


function tryHideLoading(){
    needLoadingRequestCount--
   if(needLoadingRequestCount == 0){
       endLoading()
   }
}

export let link_BURL = 'https://lywww.ley666.net/api'

const service = axios.create({
    baseURL: link_BURL,
    timeout: 15000,
    headers: {
       get: {
            'Content-Type': 'application/x-www-form-urlencoded;'
        },
        post: {
            'Content-Type': 'application/json;'
        }
    }
})



// 请求拦截器

service.interceptors.request.use(config => {
    // 添加 token
    let _token = JSON.parse(window.localStorage.getItem('token'))
    if(_token){
        config.headers.Authorization = _token.token_type + _token.access_token
        let expires_at = new Date(_token.expires_at);
        let newdata = new Date();
        if (newdata.getTime() > (expires_at.getTime() + _token.expires_in * 1000)) {
            window.localStorage.clear('islogin')
            window.localStorage.clear('userinfo')
            window.localStorage.clear('token')
            console.log('登陆时间过时');
            store.dispatch("CunisLogin",0)
            route.push({
                name:'index'
            })
        }
    }

    //添加 Loading
    showfullLoading()

    return config;
}, Error => {
    tryHideLoading()
    return Promise.reject(Error)
})


// 响应拦截器

service.interceptors.response.use(response => {

    // 清除 Loading
   tryHideLoading() 
    
    return response.data;
}, Error => {
   tryHideLoading()

   if(Error.response.status == '401'){
    Message.error('登陆状态已失效，请重新登录！')
    window.localStorage.clear('islogin')
    window.localStorage.clear('userinfo')
    window.localStorage.clear('token')
    store.dispatch("CunisLogin",0)
    route.push({
        name:'index'
    })
   }

    return Promise.reject(Error.response.data)
})


export default service
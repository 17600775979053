<template>
  <div>
    <div class="Header" :class="handerbg ? 'h-bgTwo' : 'h-bg'">
      <div class="nav">
        <!-- 左边 -->
        <div class="le-nav">
          <div class="logo">
            <router-link to="/">
              <img :src="$store.state.weblogo" alt="logo" />
            </router-link>
          </div>

          <div class="subnav">
           
            <div v-for="(item, index) in routerlist" :key="index" class="nav_list" v-show="item.is_exist">
              <router-link :to="{ name: item.path }">
              <span class="nav_span" >{{ item.title }}</span>
              </router-link>
              <div class="heiden-box">
                  <div class="game_box" > 
                     <div class="li-game" v-for="(game, indesx) in item.gamelist" :key="indesx" @click="SetGame(game)">
                      <div class="v-text" v-if="!game.is_xx">{{ game.title}}</div>
                      <img v-lazy="game.logo_url"  lazy="error"  />
                    </div>
                </div>
              </div>
            </div>
        

          </div>
        </div>



        <!-- 右边 -->
        <div class="re-nav">
          <div class="kf_box">
            <router-link to="/Promotion">
            <div class="kf">
              <img src="@/assets/header/youhui.png" />
              <p>优惠</p>
            </div>
          </router-link>
            <div class="kf" @click="Getlink(1)">
              <img src="@/assets/header/kefu.png" />
              <p>客服</p>
            </div>
          
            <div class="kf_last" @click="Getlink(2)">
              <img src="@/assets/header/rexian.png" />
              <p>热线</p>
              <!-- <div class="kf_content-box">
                <div class="showbox-link">
                 <div class="QQlinkbox">QQ</div>
                </div>
              </div> -->
            </div>
            
          </div>

          <el-form
            v-if="$store.state.islogin == 0"
            :inline="true"
            ref="formInline"
            :rules="rules"
            :model="formInline"
            class="demo-form"
            :show-message="false">
            <el-form-item prop="name">
              <el-input v-model="formInline.name" placeholder="用户名" class="inputbox" size="small" maxlength="12" minlength="6"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="formInline.password"
                placeholder="密码"
                class="inputbox"
                show-password
                size="small"
                maxlength="12"
                minlength="6"></el-input>
              <span class="pawend-text" @click="openwangdailog">忘记？</span>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" @click="onSubmit('formInline')" size="mini" class="btn">登入</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" class="btn" @click="openrequset">注册</el-button>
            </el-form-item>
            <el-form-item v-if="sms_open == '1'">
              <el-button size="mini" type="warning" plain class="btn" style="width: 80px;" @click="showkuaiLogin = true">快捷登录</el-button>
            </el-form-item>
          </el-form>

          <div class="infobox" v-else>
            <div class="actver">
              <img :src="actverList[$store.state.userinfo.head_id - 1].icon_bg" alt="#" />
            </div>
            <router-link to="/Member/Transfer">
              <span class="name">{{ $store.state.userinfo.name }}</span>
            </router-link>
            <div class="vip">
              <span>{{ $store.state.userinfo.level }}</span>
              <div class="la-conntnet">
                <div class="li-he">
                  <img src="@/assets/header/home-infoicon1.png" alt />
                  <router-link to="/Member/Transfer">
                    <p>余额</p>
                  </router-link>
                  <i class="el-icon-refresh"  :class="{refresh_active:isreshleh}"   @click="RefreshMoney"></i>
                </div>
                <p class="moeny_text">￥ {{ $store.state.userinfo.money == 0 ? '0.00' : $store.state.userinfo.money }}</p>

                <router-link to="/Member/Deposit">
                  <div class="li-io">
                    <img src="@/assets/header/home-infoicon2.png" />
                    <p>存款</p>
                  </div>
                </router-link>
                <router-link to="/Member/Withdraw">
                  <div class="li-io">
                    <img src="@/assets/header/home-infoicon3.png" />
                    <p>取款</p>
                  </div>
                </router-link>
                <router-link to="/Member/Vip">
                  <div class="li-io">
                    <img src="@/assets/header/home-infoicon4.png" />
                    <p>VIP特权</p>
                  </div>
                </router-link>
                <router-link to="/Member/MemberInfo">
                  <div class="li-io">
                    <img src="@/assets/header/home-infoicon5.png" />
                    <p>账号设定</p>
                  </div>
                </router-link>
                <div class="li-io" @click="letout">
                  <img src="@/assets/header/home-infoicon6.png" />
                  <p>退出</p>
                </div>
              </div>
            </div>
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
      </div>
    </div>


    <el-dialog  :visible.sync="BeishuVisible" width="30%">
    <div class="beishu_img">
      <img v-for="(item,k) in gamechild" :key="k" :src="item.mobile_pic" @click="SetGame(item)">
    </div>
 
   </el-dialog>



    <!-- 验证码 -->
    <Chptcha></Chptcha>
  
    <!-- 登录 -->
    <el-dialog :visible.sync="DialogLogin" width="880px" center class="diags">
      <div class="dialog-loginbox">
        <div class="letimg">
          <img :src="$store.state.weblogo" />
        </div>

        <div class="rightfrom">
          <el-form class="fromboc" :rules="rules" ref="Loginfrom" :model="Loginfrom">
            <el-form-item>
              <el-input placeholder="用户名" v-model="Loginfrom.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="密码" show-password v-model="Loginfrom.password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="btnd" @click="sunmitLogin('Loginfrom')">立即登入</el-button>
            </el-form-item>
            <el-form-item>
              <div class="ptexts">
                <p @click="openwangdailog">忘记？</p>
                <p>还没有账号？<span @click="openrequset">注册</span></p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>

    <!-- 快捷登录 -->
    <KuaiLogin  :showLogin="showkuaiLogin" @closeDaiLogin="closeDaiLogin"></KuaiLogin>

     <!-- 忘记 -->
    <DialogWang :showwang="DialogWangji" @showWangji="showWangji"></DialogWang>

    <!-- 注册 -->
    <Register :showRegister="DialogRegiter"  @isloginChptcha="isLoginCaptcha"  @closeRegiter="closeRegiter" @upLogin="Openligin" ></Register>

  </div>
</template>
<script>
import { Message, MessageBox } from 'element-ui'
import DialogWang from '@/components/Headers/Forget.vue'
import Register from '@/components/Headers/Register.vue'
import Chptcha from '@/components/Headers/Captcha.vue'
import KuaiLogin from '@/components/Headers/kuaiLogin.vue'
import {
  getLogin,
  getme,
  getgames,
  getgamelist,
  logout,
  getConfig
} from '@/http/api.js'


export default {
  name: 'Headerpage',
  components:{
    DialogWang,
    Register,
    Chptcha,
    KuaiLogin
  },
  data() {
   
    return {
      formInline: {
        name: '',
        password: '',
        key: '',
      },

      Loginfrom: {
        name: '',
        password: '',
        key: '',
      },

      BeishuVisible: false,
      gamechild: [],
      handerbg: false, // 头部背景

      actverList:[
        {
          id:1,
          icon_bg: require('../../assets/actver/1.jpg')
        },
        {
          id:2,
          icon_bg: require('../../assets/actver/2.jpg')
        },
        {
          id:3,
          icon_bg: require('../../assets/actver/3.jpg')
        },
        {
          id:4,
          icon_bg: require('../../assets/actver/4.jpg')
        },
        {
          id:5,
          icon_bg: require('../../assets/actver/5.jpg')
        },
        {
          id:6,
          icon_bg: require('../../assets/actver/6.jpg')
        },
        {
          id:7,
          icon_bg: require('../../assets/actver/7.jpg')
        },
        {
          id:8,
          icon_bg: require('../../assets/actver/8.jpg')
        },
        {
          id:9,
          icon_bg: require('../../assets/actver/9.jpg')
        },
        {
          id:10,
          icon_bg: require('../../assets/actver/10.jpg')
        }
      ],
     
      sms_open: '0', //是否开启快捷
      showkuaiLogin:false, // 快捷登录
      DialogRegiter: false, // 注册弹窗
      DialogLogin: false, // 登录弹窗
      DialogWangji: false, // 忘记弹窗
     
      isreshleh: false, // 刷新钱包
      servicelink: null, // 客服连接
      QQlink: null,
      isopenChatcha:null, // 是否打开验证码 -> 注册页面获取
     
      routerlist: [
         {
          title: '倍数场',
          gamelist:[],
          is_exist: false
        },
        // {
        //   title: '热门',
        //   path: 'Hotgame',
        //   gamelist:[],
        //   is_exist: false
        // },
        {
          title: '真人',
          path: 'Live',
          gamelist:[],
          game_type: 1,
          is_exist: false
        },
        {
          title: '电子',
          path: 'Game',
          gamelist:[],
          game_type:3,
          is_exist: false
        },
        {
          title: '棋牌',
          path: 'Board',
          gamelist:[],
          game_type: 6,
          is_exist: false
        },
        {
          title: '体育',
          path: 'Sport',
          gamelist:[],
          game_type: 5,
          is_exist: false
        },
        {
          title: '电竞',
          path: 'Esports',
          gamelist:[],
          game_type: 8,
          is_exist: false
        },
        {
          title: '彩票',
          path: 'Lottery',
          gamelist:[],
          game_type: 4,
          is_exist: false
        },
        {
          title: '捕鱼',
          path: 'Fish',
          gamelist:[],
          game_type: 2,
          is_exist: false
        }
      ],

      rules: {
        name: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 12,
            message: '用户名长度在 6 到 12 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '密码长度在 6 到 12 个字符',
            trigger: 'blur',
          },
        ],
      },
      
    }
  },

  methods: {
    // 首页直接登录 -> 提交
    onSubmit(formdata) {
      this.$refs[formdata].validate((valid, obj) => {
        // 如果 valid 为 true
        if (valid) {
          // 直接调用打开验证码函数
          this.isopenChatcha == '1' ? this.$store.dispatch('ChangeCaptcha', true) : this.setLogin(this.formInline)
        } else {
          let arr = []
          for (let key in obj) {
            arr.push(obj[key][0].message)
          }
          Message.error({
            message: arr[0],
            offset: 80,
          })
          return false
        }
      })
    },


    // 关闭快捷登录
    closeDaiLogin(bol){
      this.showkuaiLogin = bol
    },



    // 获取是否打开验证码 0 || 1
    isLoginCaptcha(obj){
      this.isopenChatcha = obj.openlogin 
      this.sms_open = obj.smsopen
    },

 

    // 弹窗登录
    sunmitLogin() {
      this.isopenChatcha == '1' ? this.$store.dispatch('ChangeCaptcha',true) : this.setLogin(this.Loginfrom)
    },



    // 打开注册弹窗
   openrequset() {
      this.DialogLogin = false
      setTimeout(() => {
        this.DialogRegiter = true
      }, 300)
    },

    // 接受注册 -> 关闭
    closeRegiter(bel){
      this.DialogRegiter = bel
    },

    Openligin(bol){
      this.DialogLogin = bol
    },


    // header 监听头部 变色
    handScroll() {
      let _scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      _scrollTop > 150 ?  this.handerbg = true : this.handerbg = false
    },

    // 跳转游戏
    SetGame(item) {
    if(item.child){
       this.gamechild = item.child
       this.BeishuVisible = true
    }else{
      if (this.$store.state.islogin !== 0) {
        let params = JSON.parse(item.params)
        const routeData = this.$router.resolve({
          name: 'LoginToSupplier',
          query: {
            api_code: item.api_name,
            ...params,
          },
        })
        if(this.BeishuVisible) this.BeishuVisible = false
        window.open(routeData.href, '_blank', 'width=1200,height=900')
      } else {
        this.$message.error({
          message: '请登录后进入游戏',
        })
      }
    }

    },

    // 登录请求
    setLogin(data) {
      getLogin(data)
        .then(res => {
          Message.success('登录成功！')
          this.$store.dispatch('CunisLogin', 1)
          this.$store.dispatch('CunToken', res.data)
          this.setMe()
          if (this.DialogLogin) {
            this.DialogLogin = false
          }
        })
        .catch(err => {
          this.$message.error(err.message)
          this.$refs.formInline.resetFields();
        })
    },

    // 获取用户信息
    setMe() {
      getme()
        .then(res => {
          this.$store.dispatch('CunInfo', res.data)
        })
        .catch(err => {
          console.log(err.message)
        })
    },

    // 获取游戏标题
   async setgemetitle() {
    try {
      let {data:arr} = await getgames()
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].title == 'x2' || arr[i].title == 'x5' || arr[i].title == 'x10' || arr[i].title == 'x20'){
          this.routerlist[0].gamelist.push({
            title: arr[i].title,
            child: arr[i].child,
            is_xx: true,
            logo_url: require(`../../assets/beishu/${arr[i].title}.png`)
          })
          this.routerlist[0].is_exist = true
      }else{
        for (let key in this.routerlist) {
         if (this.routerlist[key].title == arr[i].title) {
          this.routerlist[key].is_exist = true
         }
        }
      }
  }

      this.ForGame()
    } catch (error) {
      console.log(error);
    }
  },


  ForGame() {
      let len = this.routerlist.length
      for (let i = 0; i < len; i++) {
        let item = this.routerlist[i]
        if (item.title !== '热门' && item.title !== '倍数场' ) {
          getgamelist(item.game_type).then(red => {
            item.gamelist = red.data
          }).catch(err => {
            console.log(err.message);
          })
        }
      }
  },



    // 打开忘记密码弹窗
    openwangdailog() {
      this.DialogLogin = false
      setTimeout(() => {
        this.DialogWangji = true
      }, 300)
    },

    // 关闭忘记弹窗
    showWangji(bool){
      this.DialogWangji = bool
    },


   

    //退出
    async letout() {
      MessageBox.confirm('此操作将退出当前用户, 是否继续?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          logout()
            .then(() => {
              window.localStorage.clear()
              Message({
                type: 'success',
                message: '退出成功!',
              })

              this.$store.dispatch('CunisLogin', 0)
              this.$store.dispatch('CunInfo', '')
              this.$router.push('/')
            })
            .catch(err => {
              Message({
                type: 'info',
                message: err.messages,
              })
            })
        })
        .catch(() => {
          Message({
            type: 'info',
            message: '已取消退出',
          })
        })
    },
 
   
    // 刷新钱包
    RefreshMoney() {
      if (!this.isreshleh) {
        this.setMe()
        this.isreshleh = true
        let that = this
        that.$message.success({
          message: '刷新成功！',
          duration: 2000,
          onClose() {
            that.isreshleh = false
          },
        })
      } else {
        this.$message.error('请勿频繁操作！')
      }
    },

    // 客服请求
   async Setservice(){
      let url = window.location.host
      let res = await getConfig(url,'service')
      window.localStorage.setItem('link', res.data.service_link)
      this.servicelink = res.data.service_link
      this.QQlink = res.data.service_link
    },

    // 打开客服窗口
    Getlink(id){
      let link = null
      id == 1 ?  link = this.servicelink : link = this.QQlink
      window.open(link, '_blank', 'width=1100,height=900')
    },

  },

  mounted() {
    this.Setservice()
    this.setgemetitle() // 获取游戏标题
    window.addEventListener('scroll', this.handScroll)
  },

  watch:{
    '$store.state.key'(nval){
      if(nval){
        this.formInline.key = nval
        this.Loginfrom.key = nval
        if( this.formInline.password !== '') this.setLogin(this.formInline)
        if(this.Loginfrom.password !== '') this.setLogin(this.Loginfrom)
      }
    }
  }

}
</script>
<style  src="@/styles/headers.less" lang="less" scoped></style>
